/*eslint-disable*/
import React from 'react'
import MediaQuery from 'react-responsive'
import Layout from '../../components/layout'

import {
	Container,
	Section,
	Row,
	Column,
	ContactForm,
} from '../../components/layouts'

import {
	Title,
	Paragraph,
	BlogIndex,
	Image,
	FooterMenu,
	FooterBlock,
	SocialBtn,
	Link,
} from '../../components/ui'

import theme from '../../theme/theme'

const {
	intro,
	footer,
} = theme.sections

const posts = [
	// {
	// 	slug: '/blog/posts/account_and_donations',
	// 	title: "The 1, 2, 3's of Account Types",
	// 	date: '11.11.19',
	// 	blurb: 'With so many account types, and donation options, it can be confusing. There are checking accounts, credit cards, linked accounts, and donation accounts, each serving a unqiue purpose. Fundamentally, we break accounts into three groups, Depository accounts, Trackable accounts, and 16-digit credit card numbers and each one has unqiue features that serve a purpose. Depository accounts are great for charging your donation; to keep bank fees down, we ask Flourish users to sign in to their bank that holds their checking account. This keeps...'
	// },
	{
		slug: '/blog/posts/millennialsBecomeDonors',
		title: 'Turning Millennials into Donors',
		date: '12.20.18',
		blurb: 'Millennials--the generation rapidly changing the way we eat, work, exercise, shop, and now, the way we give. With Millennials  making up the largest portion of the population, it is imperative that nonprofits begin engaging and securing them as donors. But despite making up more than a quarter of the population, Millennials account for only 11% of charitable giving. This may come as a surprise, but Millennials have really good intentions. Often referred to as the purpose-driven generation, Millennials care about doing social good and yearn to make an impact on the world around them. But there’s a problem--according Blackbaud’s Next Generation of American Giving study, only 25% of Millennials believe that monetary giving is the best way to make a difference with charitable organizations.'
	},
	{
		slug: '/blog/posts/MillennialsChapter7/',
		title: 'Chapter 7: Lifetime Donors',
		date: '12.17.18',
		blurb: 'So you have some millennials on the hook--maybe they’ve given to your crowdfunding campaign, donated one time, or are part of a campus chapter. Think about your organization like a restaurant. Your donors have given you their business--they’ve “come to eat” once. But you don’t want them to just eat once. You want them to come back, to become a regular. The moment they sit down and choose to order the ball moves to your court--whether they come back and eat again is now contingent on your service. How well will you serve your customer?...'
	},
	{
		slug: '/blog/posts/MillennialsChapter6/',
		title: 'Chapter 6: Donate!',
		date: '12.10.18',
		blurb: 'Take just a moment to put yourself in a hypothetical situation. You’re a 25-year-old browsing Instagram while waiting for latte at Starbucks. You look through your feed and watch a powerful video about a 7-year-old Ugandan boy named Kato who doesn’t have school supplies. Right under that video you see a button that says “Donate $10 to buy a backpack of school supplies for a child like Kato”. You glance back at the video--how could you say no to a child as cute as Kato? You press the...'
	},
	{
		slug: '/blog/posts/MillennialsChapter5/',
		title: 'Chapter 5: Ambassadors',
		date: '12.3.18',
		blurb: 'Who is better at engaging millennials than millennials themselves? Millennials like to think of themselves as “world changers” or “social catalysts”. They not only want to support good work being done, they want to be involved in getting that good work done (it’s all part of their search for purpose). So what better way to get them involved by motivating them to fundraise for the organization they love? How do you do this? Peer-to-peer fundraising. This is a special form of crowdfunding--that buzzword...'
	},
	{
		slug: '/blog/posts/MillennialsChapter4/',
		title: 'Chapter 4: Social Media',
		date: '11.26.18',
		blurb: 'Social Media. This is probably what you’ve taken away from every Google search you’ve ever done on millennials. Well, there’s a reason for that--to say they love their social media is an understatement. They can’t (or at least think they can’t) live without it. Not that it’s right, but millennials will often judge the credibility and legitimacy of a brand, company, or organization based on their social media presence. You want to make sure your social media accounts are both active and aesthetically pleasing...'
	},
	{
		slug: '/blog/posts/MillennialsChapter3/',
		title: 'Chapter 3: Transparency',
		date: '11.19.18',
		blurb: 'Millennials are very willing to give, but they are also very specific about where they give. They don’t simply want to throw their money into the dark abyss of “charity” but want to know that their funds are actually contributing to your cause--not your office water cooler or your CEO’s salary (even though you need to fund that too). Blackbaud’s Next Generation of American Giving Study found that nearly 60% of millennials cited the ability to see the direct impact of their donation as..'
	},
	{
		slug: '/blog/posts/MillennialsChapter2/',
		title: 'Chapter 2: Social Currency',
		date: '11.12.18',
		blurb: 'Despite stereotypes of being entitled and self-seeking, millennials are actually also very generous and genuinely want to make an impact on the world around them. Not only that, but they love to tell everyone about it. 50% of millennials share about the causes they support on social media.'
	},
	{
		slug: '/blog/posts/MillennialsChapter1/',
		title: 'Chapter 1: Engagement',
		date: '11.5.18',
		blurb: 'As mentioned in the introduction, only 25% of millennials think that monetary giving is the best way to make an impact on a charitable organization. That’s because millennials gain more of a sense of purpose when they directly participate with a cause or organization. \
			By creating ways for millennials to directly interact...'
	}
]

class IndexPage extends React.Component {
	constructor (props) {
		super(props)
	}

	render () {
		return (
			<Layout>
				{/* Intro section */}
				<Section id="intro" bg={intro.bg} paddingTop={'100px'}>
					<Container>
						{/* Intro first section */}
						<Title fontSize={'50px'}>Blog</Title>
					</Container>
					<Container style={{ paddingLeft:'8em' }} >
						{posts.map(post => {return (
							<div>
							<BlogIndex key={post.title} {...post}/>
							<hr style={{ margin: '1em 0' }}/>
							</div>
						)})}
					</Container>
				</Section>
			</Layout>
		)
	}
}



export default IndexPage
